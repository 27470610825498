import * as React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import tw, { GlobalStyles } from "twin.macro"

import BackgroundImage from "gatsby-background-image"

import HeaderFixed from "../components/HeaderFixed"
import Footer from "../components/Footer"
import SEO from "../components/SEO"

const HeroContainer = tw.div`pt-32 md:pt-48 pb-16 md:pb-32`
const HeroTitleContainer = tw.div`max-w-screen-xl mx-auto text-left px-8`
const HeroTitle = tw.h1`text-white text-4xl md:text-6xl font-bold`
const MainContent = tw.main``
const LandingSectionContainer = tw.div`mt-20 md:mt-24 max-w-screen-lg mx-auto px-8`


interface LandingSectionProps {
  title: string
  content: string
  to: string
}

const LandingSection = (props: LandingSectionProps) => {
  return (
    <div tw="flex flex-col md:grid md:grid-cols-3 md:gap-4">
      <div tw="text-center md:text-left mb-4 md:mb-0 col-span-1">
        <h3 tw="text-3xl md:text-4xl font-bold">{props.title}</h3>
      </div>
      <div tw="col-span-2">
        <p tw="text-xl leading-relaxed mb-4">{props.content}</p>
        <p>
          <Link
            tw="font-bold underline hover:text-gray-700"
            to={props.to}
          >
            Learn more
          </Link>
        </p>
      </div>
    </div>
  )
}

const IndexPage = () => {
  const { heroBgImage } = useStaticQuery(
    graphql`
      query {
        heroBgImage: file(relativePath: { eq: "hero-bg-1.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `)

  return (
    <React.Fragment>
      <GlobalStyles />
      <SEO title="Long Rock Capital" />
      <HeaderFixed always={false}/>
      <BackgroundImage fluid={heroBgImage.childImageSharp.fluid}>
        <HeroContainer>
          <HeroTitleContainer>
            <HeroTitle tw="mb-4">Tech is changing</HeroTitle>
            <HeroTitle tw="mb-4">how to invest,</HeroTitle>
            <HeroTitle>Your investment will change tech</HeroTitle>
          </HeroTitleContainer>
        </HeroContainer>
      </BackgroundImage>
      <MainContent>
        <LandingSectionContainer>
          <LandingSection
            title="Quant trading"
            content={`
              Long Rock Capital started in 2015 researching, building, and delivering quantitative trading systems for futures markets. 
              In 2017, Long Rock Capital developed a low-latency trading system for cryptocurrencies.
            `}
            to="/trading"
          />
        </LandingSectionContainer>
        <LandingSectionContainer>
          <LandingSection
            title="Trustless tech"
            content={`
              Reduce investment friction with trustless technology.
              We simplify and increase transparency of asset management by reducing the need for expensive intermediaries.
            `}
            to="/trustless"
          />
        </LandingSectionContainer>
        <LandingSectionContainer>
          <LandingSection
            title="Multichain protocols"
            content={`
              We have a deep understanding of the blockchain landscape and we undertake original research to find new trends in the space.
              We help companies leverage DLTs to optimize their business processes.
              Integrate with the best networks by seamlessly sharing data and transactions across networks. 
            `}
            to="/multichain"
          />
        </LandingSectionContainer>
      </MainContent>
      <Footer />
    </React.Fragment>
  )
}

export default IndexPage
